import Api from '@/services/Index';

const getSchedulesInstructor = (module) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/prep/schedules/instructor/' + module);
}

const getSchedulesCalendar = (class_id, month, year) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/prep/schedules/calendar/' + class_id + '/' + month + '/' + year);
}

const getStudents = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/prep/students', config);
}

const getPrepAttendancesStudents = (schedule_id, date) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/prep/attendances/' + schedule_id + '/' + date + '/students');
}

const postPrepAttendancesHours = (formdata) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/prep/attendance/hours', formdata);
}

const putPrepAttendancesFinalized = (formdata) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/prep/attendances/finalize', formdata);
}

const roster = (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/prep/attendances/roster', formData, {responseType: 'arraybuffer'});
}

export default {
    getSchedulesInstructor,
    getSchedulesCalendar,
    getStudents,
    getPrepAttendancesStudents,
    postPrepAttendancesHours,
    putPrepAttendancesFinalized,
    roster
}
